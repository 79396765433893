
html {
  -webkit-font-smoothing: antialiased;
}

body {  
  font-family: Arial,Helvetica Neue, sans-serif;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: normal;  
}

h2 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  font-size: 2.75rem;
}
h3 {
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 2rem;
}

.logo {
  text-transform: lowercase;
}

.intro {
  font-size: 2.25rem;
}

.extra, .url {
  font-size: 1.5rem;
  line-height: 2rem;
}

.contact {
  h3 {
    font-size: 1.85rem;
    margin-top: 35px;
    text-transform: none;
  }
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@media only screen and (max-width: 70em) {
  .projects .project {
    padding: 15px;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 1.75rem;
    // margin-bottom: 0px;
  }
  .intro {
    font-size: 1.35rem;
    line-height: 1.5rem;
  }
  .extra, .url {
    font-size: 1.25rem;
  }

  .about {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }


  }