/* Put your layout here */

body {
  overflow: hidden;
}

.logo {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: $bgcolor;
  text-align: right;
  padding-right: 10px;
  height: 47px;
  border-bottom: 3px solid $maincolor;
  z-index: 1;
  &:before {
    content: 'w';
  }
  &:hover {
    &:before {
      content: 'waanz';
    }
  }
}

.tools {
  position: fixed;
  top:3px;
  left:70px;
  z-index: 3;
  .updown {
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);   
    transform:rotate(180deg);
    
    &.open {
      transform:rotate(0deg);
      margin-top:1px;
    }
  }
}

.page-detail .updown:hover {
  transform:rotate(180deg);
      margin-top:1px;
}

.navhover {
  .updown {
    transform:rotate(90deg) !important;
  }
}
.projectshover {
  .updown {
    transform:rotate(0deg);
  }
}
.logohover {
  .updown {
    transform:rotate(-90deg) !important;
  }
}

nav {
  cursor: pointer;
  position: fixed;
  left:0;
  z-index: 2;
  background:$bgcolor;
  width:calc(100vh - 50px);    
  height:44px;  
  transform-origin: left top;
  transform:rotate(-90deg) translateX(-100%);
  border-bottom: 3px solid $maincolor;
  border-top:3px solid transparent;
  //transition: all 0.35s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: all 700ms cubic-bezier(0.075, 0.885, 0.32, 1.275);   
  
  ul {
    display: flex;
    justify-content: space-between;    
  }
  li {
    padding: 0 20px;
  }
}

.n-p {
  &:before {
    content: 'Selected ';
    text-transform: none !important;    
  }  
  text-transform: lowercase;  
}

.content {
  position: relative;
  display: flex;
  margin: 50px 0 0 0;
  width: calc(200%);
  transform: translateX(-50%);
  // margin-left: 0;
  //transition: all 0.35s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);   
  // transition: all 2750ms cubic-bezier(1.000, -0.245, 0.165, 1.025);
  height: 100vh;
  overflow: hidden;
}

.page-about {
  .content {
    transform: translateX(0%);
  }
  nav {
    left:calc(100% - 50px);
    border-top:3px solid $maincolor;
    border-bottom:3px solid transparent;
  }

  .n-p {
    text-transform: none;
    &:before {
      display: none;
    }
  }

  .n-a {
    &:after {
      content: ' us';    
    }  
  }
  
  &.navhover {
    .updown {
      transform:rotate(-90deg);
    }
  }
  .updown {
    transform:rotate(0deg);
  }
}


.projects, .about {
  width: 100%;
  padding: 0;
  height: auto;
  // overflow-y: auto;
  margin-bottom: 50px;
}

.about {
  // background:pink;
  display: flex;
  overflow: hidden;
  margin-right: 50px;
}
.about {
  .txt, .contact {
    padding:25px;
    width: calc(50%);  
  }
  .txt {
    overflow-y:auto;
    -webkit-overflow-scrolling: touch;
  }

  .contact {
    width: 33%;
    padding-left: 17%;
    p {
      // margin-top: 10px;
    }
  }
  
}

.projects {
  // background: blue;
  margin-left: 50px;
  transition: all 0.35s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
  display: flex;
  position: relative;
  overflow: hidden;
}

.projectlist, .projectdetail {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.projectlist {
  border-right: 3px solid $maincolor;
}

.project {
  position: relative;
  cursor: pointer;
  padding:25px;
  // background:pink;
  border-bottom: 3px solid $maincolor;
  transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
  
  .extra, .url {
    display: none;
  }
  
  .extra {
    margin-top: 20px;
    position: relative;

  }
  .url {
    // position: absolute;
    margin-top: 30px;
    // margin-bottom: 30px;
    padding-left: 35px;
    color: white;
    position: relative;
    // float: right;
    &:before{
      position: absolute;
      content: '☞';
      left:0;
      top:3px;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:hover {
      text-decoration: none;
      &:before {
        left:5px;
      }
    }
  }
  
  &.open {
    background:$maincolor;
    color:white;
    .extra, .url {
      display: block;
    }
  }
  
}


.projectdetail {
  display: none;
  
  .img {
    margin: 0 ;
    background:#e9e9e9;
    border-bottom:3px solid $maincolor;
    img {
      opacity: 0;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    img.loaded {      
        opacity: 1;
      
    }
    // box-shadow: -7px 7px 14px 9px rgba(0,0,0,0.75);
  }
}

.page-detail {
  .projectlist, .projectdetail {
    width: 50%;    
  }

  .projectdetail {
    display: block;
  }
}

/*Entirely optional – just wanted to remove the scrollbar on WebKit browsers as I find them ugly*/
.about .txt::-webkit-scrollbar,
.projectlist::-webkit-scrollbar,
.projectdetail::-webkit-scrollbar {
  display: none;
}



@media only screen and (max-width: 70em) {
  
  .page-about {
    .about {
      display: block;
      overflow-y:auto;
       -webkit-overflow-scrolling: touch;
      padding-right: 100px;
    }
    .about::-webkit-scrollbar {
        display: none;
      }
    .about {
      .txt, .contact {
        width: 100%;  
        padding: 0 25px;
      }
      .contact {
        margin-top: 15px;
      }
    }

    h3 {
      margin-top: 0;
      text-transform: none;
    }
  }

  .page-detail {
    .projects {
      flex-direction:column;
      .projectlist, .projectdetail{
        width: 100%;
        height: calc(50vh - 25px);  
      }

      .projectdetail {
        border-top:3px solid $maincolor;
      }
      
    }
  }
  
}